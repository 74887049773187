<template>
  <div>
    <validation-observer ref="createHelpForm">
      <b-form class="mt-2" @submit.prevent="createHelp">
        <b-container>
          <b-row>
            <b-col>
              <b-form-group label="Título" label-for="helpData-title">
                <validation-provider #default="{ errors }" name="Título" rules="required">
                  <b-form-input id="helpData-title" v-model="helpData.title" :state="errors.length > 0 ? false : null"
                    name="title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider #default="{ errors }" name="Tipo" rules="required">
                <b-form-group label="Tipo" label-for="type_help" :state="errors.length > 0 ? false : null">
                  <v-select v-model="helpData.type" :options="type_help" :clearable="false" input-id="type_help" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <div v-if="helpData.type != null && helpData.type.value == '2'">
                <validation-provider #default="{ errors }" name="Pregunta superior" rules="required">
                  <b-form-group label="Pregunta superior" label-for="parent_id"
                    :state="errors.length > 0 ? false : null">
                    <v-select v-model="helpData.parent_id" :options="items_select" :clearable="false"
                      input-id="parent_id" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <div class="mb-2">
                  <div>¿La pregunta va a tener la respuesta?</div>
                  <b-form-checkbox switch size="lg" v-model="helpData.info_description" variant="success" />
                </div>

                <b-form-group label="Respuesta" label-for="helpData-description" v-if="helpData.info_description">
                  <validation-provider #default="{ errors }" name="Respuesta" rules="required">
                    <b-form-textarea id="helpData-description" v-model="helpData.description"
                      :state="errors.length > 0 ? false : null" name="helpData-description" rows="10" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <div class="d-flex justify-content-center border-top align-items-center py-5">
                <b-button type="submit" variant="primary">Guardar</b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </validation-observer>
  </div>
</template>
  
<script>
import {
  BContainer,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormSelect,
  BCard,
  VBToggle,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

const helpDataInitialData = {
  id: null,
  title: null,
  description: null,
  type: null,
  parent_id: null,
  info_description: false
}

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BButton,
    vSelect,
    BCard,
    FeatherIcon,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      helpData: Object.assign({}, helpDataInitialData),
      type_help: [
        { value: "1", label: "Principal" },
        { value: "2", label: "Secundaria" },
      ]
    }
  },
  computed: {
    ...mapState({
      items_select: (state) => state.help.items_select,
    }),
  },
  methods: {
    getItemsSelect() {
      this.$store.dispatch('help/getSelect', {})
    },

    getItems(page = 1, filters = {}) {
      this.$store.dispatch('help/get', {
        currentPage: page,
        filters,
      })
    },
    createHelp() {
      this.$refs.createHelpForm.validate().then(async (success) => {
        if (success) {
          const res = await this.$store.dispatch('help/save',
            {
              id: this.helpData.id,
              title: this.helpData.title,
              description: this.helpData.description,
              type: this.helpData.type,
              parent_id: this.helpData.parent_id,
              info_description: this.helpData.info_description,
            }
          )
          this.helpData = Object.assign({}, helpDataInitialData)
          this.$refs.createHelpForm.reset()
          this.$emit('saved', res)

          if (res.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Pregunta frecuente guardada correctamente',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            this.$store.dispatch('help/get', {
              currentPage: 1,
            })
          } else {
            this.$swal('Error', 'Hubo un error al guardar la pregunta frecuente', 'error')
          }
        }
      })
    },
  },
  watch: {
    open: {
      handler: function (val) {
        if (val) {
          this.getItemsSelect()
        }
      }
    },
    item: {
      handler: function (val) {
        if (val) {
          this.helpData = Object.assign({}, val)
          let verify_type = this.type_help.find((item) => item.value == val.type)
          this.helpData.type = (verify_type != undefined) ? verify_type : null

          if (val.description != null) {
            this.helpData.info_description = true
          }

          if (val.parent_id != null && val.parent_text != "No") {
            this.helpData.parent_id = { value: val.parent_id, label: val.parent_text }
          } else {
            this.helpData.parent_id = null
          }

        } else {
          this.helpData = Object.assign({}, helpDataInitialData)
        }
      },
      deep: true,
    },
  },
}
</script>
  