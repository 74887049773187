var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"createHelpForm"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.createHelp($event)}}},[_c('b-container',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Título","label-for":"helpData-title"}},[_c('validation-provider',{attrs:{"name":"Título","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"helpData-title","state":errors.length > 0 ? false : null,"name":"title"},model:{value:(_vm.helpData.title),callback:function ($$v) {_vm.$set(_vm.helpData, "title", $$v)},expression:"helpData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo","label-for":"type_help","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"options":_vm.type_help,"clearable":false,"input-id":"type_help"},model:{value:(_vm.helpData.type),callback:function ($$v) {_vm.$set(_vm.helpData, "type", $$v)},expression:"helpData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),(_vm.helpData.type != null && _vm.helpData.type.value == '2')?_c('div',[_c('validation-provider',{attrs:{"name":"Pregunta superior","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pregunta superior","label-for":"parent_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"options":_vm.items_select,"clearable":false,"input-id":"parent_id"},model:{value:(_vm.helpData.parent_id),callback:function ($$v) {_vm.$set(_vm.helpData, "parent_id", $$v)},expression:"helpData.parent_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4270062255)}),_c('div',{staticClass:"mb-2"},[_c('div',[_vm._v("¿La pregunta va a tener la respuesta?")]),_c('b-form-checkbox',{attrs:{"switch":"","size":"lg","variant":"success"},model:{value:(_vm.helpData.info_description),callback:function ($$v) {_vm.$set(_vm.helpData, "info_description", $$v)},expression:"helpData.info_description"}})],1),(_vm.helpData.info_description)?_c('b-form-group',{attrs:{"label":"Respuesta","label-for":"helpData-description"}},[_c('validation-provider',{attrs:{"name":"Respuesta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"helpData-description","state":errors.length > 0 ? false : null,"name":"helpData-description","rows":"10"},model:{value:(_vm.helpData.description),callback:function ($$v) {_vm.$set(_vm.helpData, "description", $$v)},expression:"helpData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,381458967)})],1):_vm._e()],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-center border-top align-items-center py-5"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Guardar")])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }