<template>
    <div>
        <b-card>
            <b-row class="pb-1">
                <b-col md="3" cols="6">
                    <b-form-input v-model="searchValue" placeholder="Buscar" debounce="400" />
                </b-col>
                <b-col md="9" cols="6" class="text-right">
                    <b-button variant="primary" class="px-1" @click="create()"
                        v-if="permissions_requerid.includes('create-help-question')">
                        <feather-icon size="1.2x" icon="PlusIcon" />
                    </b-button>
                </b-col>
            </b-row>
            <b-table hover responsive="sm" :items="items" :fields="fields" :per-page="perPage" :busy="loading"
                show-empty>
                <template #cell(title)="data">
                    {{ data.item.title }}
                </template>

                <template #cell(type)="data">
                    {{ (data.item.type == "1") ? "Principal" : "Secundaria" }}
                </template>


                <template #cell(actions)="data">
                    <div class="d-flex align-items-center">
                        <help-change-active-status :help="data.item"
                            v-if="permissions_requerid.includes('edit-help-question')" />

                        <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" />
                            </template>
                            <b-dropdown-item @click="edit(data.item)"
                                v-if="permissions_requerid.includes('edit-help-question')">Editar</b-dropdown-item>
                            <b-dropdown-item @click="deleteItem(data.item)"
                                v-if="permissions_requerid.includes('delete-help-question')">Eliminar</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </template>

                <template #table-busy>
                    <div class="text-center text-primary my-5 pt-3">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong class="ml-1">Cargando</strong>
                    </div>
                </template>

                <template #empty>
                    <div class="text-center my-5 pt-3 d-flex w-100 justify-content-center align-items-center">
                        <feather-icon icon="AlertCircleIcon" size="1.5x" />
                        <strong class="ml-1">No hay registros para mostrar</strong>
                    </div>
                </template>
            </b-table>
            <b-row v-show="items.length > 0 && !loading">
                <b-col md="2" sm="12" class="d-none d-sm-block">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block mr-50">Por página</label>
                        <b-form-select id="perPageSelect" :value="perPage" size="sm" :options="pageOptions"
                            @change="perPageChange" style="width: auto" />
                    </b-form-group>
                </b-col>
                <b-col v-show="totalRows > perPage" md="10" sm="12">
                    <b-pagination striped hover responsive first-number last-number :value="currentPage"
                        :total-rows="totalRows" :per-page="perPage" align="right" size="sm" class="my-0"
                        @change="changePage" />
                </b-col>
            </b-row>

            <b-sidebar width="50%" v-model="open" bg-variant="white" :title="titleSideBar" right backdrop shadow>
                <AddUpdateHelp :item="item" :open="open" @saved="() => openSidebar(false)" />
            </b-sidebar>
        </b-card>
    </div>
</template>
  
<script>
import {
    BCard,
    BCardText,
    BTable,
    BCol,
    BPagination,
    BRow,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BSidebar,
    VBToggle,
    BSpinner,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import { confirmAlert } from '@/helpers/utils'

import AddUpdateHelp from './components/AppUpdateHelp'
import HelpChangeActiveStatus from './components/HelpChangeActiveStatus'
import { verifyPermission } from '@/router';

export default {
    components: {
        BCard,
        BCardText,
        BTable,
        BCol,
        BPagination,
        BRow,
        BFormGroup,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BButton,
        BSidebar,
        BSpinner,
        AddUpdateHelp,
        HelpChangeActiveStatus,
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
        return {
            open: false,
            searchValue: '',
            item: null,
            titleSideBar: "",
            fields: [
                {
                    key: 'title',
                    label: 'Título',
                    sortable: true,
                },
                {
                    key: 'type',
                    label: 'Tipo',
                    sortable: true,
                },
                {
                    key: 'parent_text',
                    label: 'Pertenece',
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: 'Acciones',
                    sortable: false,
                },
            ],
            permissions_requerid: []
        }
    },
    computed: {
        ...mapState({
            items: (state) => state.help.items,
            perPage: (state) => state.help.perPage,
            currentPage: (state) => state.help.currentPage,
            totalRows: (state) => state.help.totalRows,
            pageOptions: (state) => state.help.pageOptions,
            searchTerm: (state) => state.help.searchTerm,
            loading: (state) => state.help.loading,
        }),
    },
    methods: {
        filtersChanged(filters) {
            this.getItems(1, filters)
        },
        create() {
            this.item = null
            this.titleSideBar = 'Crear pregunta frecuente'
            this.openSidebar()
        },
        edit(item) {
            this.item = item
            this.titleSideBar = 'Editar pregunta frecuente'
            this.openSidebar()
        },
        async deleteItem(item) {
            const res = await confirmAlert('¿Está seguro de eliminar este registro?')
            if (res.value) {
                const r = await this.$store.dispatch('help/delete', {
                    id: item.id,
                })
                this.$swal('Eliminado', 'El registro ha sido eliminado', 'success')
            }
        },
        openSidebar(open = true) {
            this.open = open
        },
        getItems(page = 1, filters = {}) {
            this.$store.dispatch('help/get', {
                currentPage: page,
                filters,
            })
        },
        changePage(page) {
            this.getItems(page)
        },
        perPageChange(perPage) {
            this.$store.dispatch('help/perPageChange', perPage)
            this.getItems()
        },
        search(value) {
        },
    },
    mounted() {
        this.getItems()

        // Verify
        let create = verifyPermission({ namePerm: 'create-help-question' })
        let edit = verifyPermission({ namePerm: 'edit-help-question' });
        let delete_var = verifyPermission({ namePerm: 'delete-help-question' })

        let permissions_requerid_verify = []
        if (create) {
            permissions_requerid_verify.push("create-help-question")
        }
        if (edit) {
            permissions_requerid_verify.push("edit-help-question")
        }
        if (delete_var) {
            permissions_requerid_verify.push("delete-help-question")
        }

        this.permissions_requerid = permissions_requerid_verify

    },
    watch: {
        searchValue(value) {
            this.$store.dispatch('help/searchTermChange', value)
            this.getItems()
        },
    },
}
</script>
  
<style>
.badge-primary {
    background-color: #94a0ff;
}
</style>
  