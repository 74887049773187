<template>
    <b-form-checkbox switch size="lg" v-model="status" @change="changeActiveStatus" variant="success" />
</template>
<script>
import { BFormCheckbox } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    name: 'HelpChangeActiveStatus',
    components: {
        BFormCheckbox,
    },
    props: {
        help: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            status: false,
        }
    },
    methods: {
        async changeActiveStatus() {
            this.loading = true
            try {
                const res = await this.$store.dispatch('help/changeActiveStatus', {
                    id: this.help.id,
                    status: this.status,
                })

                if (res?.success) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.message,
                            icon: 'CheckCircleIcon',
                            variant: 'success',
                        },
                    })
                } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.message,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    })
                }
            } catch (error) {
                this.$store.dispatch('alert/error', error)
            }
            this.loading = false
        },
    },
    mounted() {
        this.status = this.help.status
    },
}
</script>
  